/* eslint-disable no-undef */
// function centralizeY (box) {
//
//     var halfBoxHeight = $(box).height() * .5;
//     var halfWindowHeight = $(window).height() * .5;
//
//     $(box).css({
//         'top' : halfWindowHeight - halfBoxHeight
//     });
// }
// function centralizeX (box) {
//
//     var halfBoxWidth = $(box).width() * .5;
//     var halfWindowWidth = $(window).width() * .5;
//
//     $(box).css({
//         'left' : halfWindowWidth - halfBoxWidth
//     });
// }
// function centralize (box) {
//
//     centralizeX(box);
//     centralizeY(box)
//
// }

// centralize('.login-box, .register-box');
//
// $(window).resize(function () {
//     centralizeX('.login-box, .register-box');
// });


$(window).load(function () {
	$('.background-load').fadeIn(1000)
})